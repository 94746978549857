<template>
  <div class="home w-full min-h-screen flex flex-col items-center">
    <div class="flex flex-col items-center justify-center md:w-11/12">
      <img src="@/assets/images/logo.png" alt="Bingo Peluditos Club Mars 2022" class="mt-16 mb-6 w-6/12 md:w-2/12 md:hidden" />
      <img src="@/assets/images/bingo.png" alt="Bingo Peluditos Club Mars 2022" class="w-10/12 mb-8 md:w-3/12 md:mt-4" />
      <div class="w-10/12 md:w-8/12 h-52 custom-h rounded">
        <iframe src="https://player-cdn.logicideas.media/embed/LIf2280863/" width="100%" height="100%" frameborder="0" scrolling="auto" allowfullscreen="allowfullscreen"></iframe>
      </div>
      <img src="@/assets/images/logo.png" alt="Bingo Peluditos Club Mars 2022" class="mt-4 w-40 hidden md:block" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.home {
  background: url("../assets/images/bg-2.png") no-repeat;
  background-size: 100% 100%;

  @media only screen and (min-width: 780px) {
    background: url("../assets/images/bg-dt.png") no-repeat 50% 50%;
    background-size: 100% 100%;
  }
}

.custom-h {
  @media only screen and (min-width: 780px) {
    height: 60vh;
  }
}
</style>
